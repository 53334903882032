import React from 'react';
import {Link} from 'gatsby'
import {Grid} from '@material-ui/core';
import ProjectStart1 from '../../assets/images/project-start1.png'
import CircleShape1 from '../../assets/images/shape/circle-shape1.png'
import shape2 from '../../assets/images/shape/circle-shape2.png'

const ProjectStartArea = () => {
    return (
        <div className="projects-area bg-laptop pt-100 pb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-image">
                        <img
                          src='https://s3.us-west-1.amazonaws.com/outspoke-images/computerCropped1.png'
                          style={{width: 600, paddingRight: 20}}
                          alt='METRC' />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12" style={{paddingLeft: 42}}>
                        <div className="project-start-content">
                          <img
                            src='https://s3.us-west-1.amazonaws.com/outspoke-images/OUTSPOKE_LOGO-2019_WEB-white.png'
                            style={{width: 240, position: 'relative', right: 14}}
                            alt='Outspoke'
                          />
                          <Grid container>
                            <a href="https://b1.outspoke.io/pricing" className="default-btn">
                                <i className="flaticon-web"></i>
                                Get Started For Free
                                <span></span>
                            </a>
                          </Grid>
                        </div>
                    </div>
                </div>
            </div>

            {/*<div className="circle-shape2">
                <img src={shape2} alt="image" />
            </div>
            <div className="circle-shape1">
                <img src={CircleShape1} alt="image" />
            </div>*/}

        </div>
    )
}

export default ProjectStartArea;
