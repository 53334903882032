import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import StartProject from '../components/Common/StartProject'
import ProjectStartArea from '../components/MachineLearningAISolutions/ProjectStartArea';

const FAQ = () => {
    return (
        <Layout>
            <Navbar />
            {/*<PageBanner
                pageTitle="FAQ"
                homePageText="Home"
                homePageUrl="/"
                activePageText="FAQ"
            />*/}
            <div className="pt-100 pb-100">
                <div className="container">
                <h2 style={{marginBottom: 42}}>FAQs</h2>
                    <div className="faq-accordion">
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q1. Is Outspoke a cannabis wholesale marketplace?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Outspoke is a supply chain management platform that layers analytic and networking value on top of required compliance data.
                                    </p>
                                    <p>
                                    Improving supply chain management allows businesses to avoid passive marketplace environments where products sit without sale.
                                    </p>
                                    <p>
                                    Data driven inventory management supports strategic allocation and better relationship management so the right products get made and shared in places that truly support your unique business needs.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            {/*<AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q2. I need an affordable supply chain management platform - am I looking in the right place?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        You sure are. Outspoke offers an unbeatable price tag for actionable data and inventory visibility. Our Tier 1 plan is available for free.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>*/}

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q2. Will I remain compliant under my state cannabis regulations while using Outspoke?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Without a doubt! That's one reason why inputting your METRC API on Outspoke is so important.
                                    </p>
                                    <p>
                                    Outspoke is regularly in-sync with your METRC data, providing much needed internal visibility.
                                    </p>
                                    <p>
                                    Viewing, parsing and exporting data is all there for you to leverage in support of your improved compliance and business intelligent environment.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q3. Does Outspoke push data to METRC?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    No! We provide a read-only environment that is synchronized to your data.
                                    </p>
                                    <p>
                                    Outspoke chooses to do that for a reason - we are designed to add value without creating noise or conflict with your existing compliance processes.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q4. What is inventory visibility?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Inventory visibility is the potential for a business to see inventory data in real-time throughout the supply chain.
                                    </p>
                                    <p>
                                    For example, if you run a brand it's possible to see your inventory levels and sales transactions at retailers who sell your products - updated every day.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q5. Why is inventory visibility important to supply chain management?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Visibility gives businesses the power to understand their inventory better, allowing them to identify revenue opportunities and improvements.
                                    </p>
                                    <p>
                                    Visibility is also a critical tool for brand management - helping brands support shared customers by keeping the shelves stocked and targeting marketing campaigns at products that are getting old or not selling quickly.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q6. What kind of data helps businesses with supply chain management?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Inventory data allows any cannabis businesses to better understand the processes throughout their supply chain. Without visibility into these data points, cannabis business strategy geared towards enhancing productivity becomes guesswork – and that can be super frustrating.
                                    </p>
                                    <p>
                                    Retail sales transactions can also help inform decisions from what strains to plant through what products to create and how to market them.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q7. What is a METRC API Key?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Essentially, it is a unique password that allows your business to connect your METRC data to additional software.
                                    </p>
                                    <p>
                                    Check out our article on getting your METRC API documentation!
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q8. How do I find my METRC API Key?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <p>
                                    <ol>
                                      <li>Select the correct permissions in METRC</li>
                                      <li>Go to your User Profile on METRC</li>
                                      <li>Select API Keys</li>
                                      <li>Press Generate</li>
                                    </ol>
                                    <p>
                                    When signing up for Outspoke..
                                    </p>
                                    <ol>
                                      <li>Select your state of operation (CA, OR, CO, AK, MI, ME, MA)</li>
                                      <li>Enter the copied API key</li>
                                    </ol>
                                  </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                        </Accordion>
                    </div>
                </div>
            </div>
            <ProjectStartArea />
            <Footer />
        </Layout>
    );
}

export default FAQ
